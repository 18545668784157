import "core-js/modules/es.array.push.js";
import { editActive, courseList, getActiveInfo, getVoucherList, refundRuleList } from "@/api/api";
import { projectName } from "@/utils/config";
import editor from "@/components/editor.vue";
import editor1 from "@/components/editor1.vue";
import editor2 from "@/components/editor2.vue";
import editor3 from "@/components/editor3.vue";
export default {
  name: "index",
  components: {
    editor,
    editor1,
    editor2,
    editor3
  },
  data() {
    return {
      cityValue: [],
      goodsCodeValue: [],
      projectName: projectName,
      dialogVisible: false,
      dialogImageUrl: '',
      showImageVisible: false,
      showImageUrl: '',
      token: localStorage.getItem('token'),
      docKeys: ['year'],
      insureValue: [],
      Info: {
        id: '',
        name: '',
        use_voucher: '否',
        courseIdsArr: [],
        active_day: '',
        active_type: '',
        file_path_arr: [],
        content: '',
        active_process: '',
        sign_reading: '',
        active_img_arr: [],
        share_poster_img: '',
        note_attention: ''
      },
      teacherList: [],
      levelList: [],
      goodsTypeList: [],
      type: 0,
      checkMuneSer: [],
      insureList: [],
      courseList: [],
      is_info: '',
      rules: {
        name: [{
          required: true,
          message: "请输入名称",
          trigger: 'blur'
        }],
        active_day: [{
          required: true,
          message: "请输入有效的活动天数",
          trigger: 'blur'
        }]
      },
      user: [],
      voucherList: [],
      ruleList: []
    };
  },
  created() {},
  mounted() {
    this.user = JSON.parse(localStorage.getItem('user')) || null;
    let data = this.$root.useRoute.query;
    this.Info.id = data.id;
    this.is_info = data.is_info;
    this.getCourseList();
    this.getVoucherList();
    this.getRefundRuleList();
  },
  methods: {
    qxBtn() {
      this.$root.useRouter.go(-1);
    },
    getInfo() {
      getActiveInfo({
        id: this.Info.id
      }).then(res => {
        this.Info = res.data;
        this.setText();
        if (this.Info.active_img_arr) {
          this.Info.active_img_arr = JSON.parse(this.Info.active_img_arr);
          for (let i in this.Info.active_img_arr) {
            let s = this.Info.active_img_arr[i].url;
            let sarr = s.split(this.$root.projectName + '/');
            if (!sarr[1]) {
              this.Info.active_img_arr[i].url = '/' + this.$root.projectName + '/' + s;
            }
          }
        } else {
          this.Info.active_img_arr = [];
        }
        if (this.Info.file_path_arr) {
          this.Info.file_path_arr = JSON.parse(this.Info.file_path_arr);
        } else {
          this.Info.file_path_arr = [];
        }
      });
    },
    getVoucherList() {
      getVoucherList().then(res => {
        this.voucherList = res.data;
      });
    },
    getRefundRuleList() {
      refundRuleList().then(res => {
        this.ruleList = res.data;
      });
    },
    getCourseList() {
      courseList().then(res => {
        this.courseList = res.data;
        if (this.Info.id) {
          this.getInfo();
        }
      });
    },
    areaChange(e) {
      //选择地区
      this.Info.province = e[0];
      this.Info.city = e[1];
      this.Info.area = e[2];
    },
    subData() {
      //提交
      if (this.Info.type == 2) {
        if (!this.Info.active_date) {
          this.$root.ElMessage.error('家庭活动必须填写活动日期');
          return false;
        }
      }
      if (!this.Info.active_day || this.Info.active_day < 1) {
        this.$root.ElMessage.error('活动天数必须大于0');
        return false;
      }
      /*if(!this.Info.operator_settle_price || this.Info.operator_settle_price<0.01){this.$root.ElMessage.error('结算金额必须大于0');return false;}*/
      this.$refs['Info'].validate(valid => {
        if (valid) {
          if (this.Info.active_img_arr) {
            for (let i in this.Info.active_img_arr) {
              let s = this.Info.active_img_arr[i].url;
              let sarr = s.split(this.$root.projectName + '/');
              if (sarr[1]) {
                this.Info.active_img_arr[i].url = sarr[1];
              }
            }
          }
          editActive(this.Info).then(res => {
            if (res.success) {
              this.$root.ElMessage({
                type: 'success',
                message: res.msg
              });
              this.$root.useRouter.go(-1);
            } else {
              this.$root.ElMessage.error(res.msg);
            }
          });
        }
      });
    },
    showImage(e) {
      this.dialogImageUrl = e.url;
      this.dialogVisible = true;
    },
    addProcess() {
      this.Info.process.push({
        time: '',
        text: '',
        address: ''
      });
    },
    delProcess(index) {
      console.log(index);
      this.Info.process.splice(index, 1);
    },
    addCost() {
      this.Info.cost_detail_text.push({
        name: '',
        money: '',
        text: ''
      });
    },
    delCost(index) {
      this.Info.cost_detail_text.splice(index, 1);
    },
    setText() {
      this.$refs.editor.setText(this.Info.content);
      this.$refs.editor1.setText(this.Info.active_process);
      this.$refs.editor2.setText(this.Info.sign_reading);
      this.$refs.editor3.setText(this.Info.note_attention);
    },
    getText(html) {
      this.Info.content = html;
    },
    getText1(html) {
      this.Info.active_process = html;
    },
    getText2(html) {
      this.Info.sign_reading = html;
    },
    getText3(html) {
      this.Info.note_attention = html;
    },
    refreshData() {
      this.$forceUpdate();
    }
  }
};